import React from "react";
import { addWidthParameter } from "../../utils/addWidthParameter";

export default ({ data }) => {
  if (!data.content.general) {
    return null;
  }

  // data.content should be filled in, others require null check. If validation is wrong return null (if data is empty)
  const blockdata = data.content.general; // data.content.informatie && data.content.informatie.vertrekdata;

  return (
    Boolean(blockdata) && (
      <section className="page-block-3columnimagetext">
        <div className="page-block-3columnimagetext__container">
          <div className="page-block-3columnimagetext__row">
            <div className="page-block-3columnimagetext__col">
              <div
                className="page-block-3columnimagetext__header"
                style={{ backgroundImage: `url('${addWidthParameter(blockdata.image1?.url, 736)}')` }}
              />
              <div
                className="page-block-3columnimagetext__description"
                dangerouslySetInnerHTML={{ __html: blockdata.text1 }}
              />
            </div>
            <div className="page-block-3columnimagetext__col">
              <div
                className="page-block-3columnimagetext__header"
                style={{ backgroundImage: `url('${addWidthParameter(blockdata.image2?.url, 736)}')` }}
              />
              <div
                className="page-block-3columnimagetext__description"
                dangerouslySetInnerHTML={{ __html: blockdata.text2 }}
              />
            </div>
            <div className="page-block-3columnimagetext__col">
              <div
                className="page-block-3columnimagetext__header"
                style={{ backgroundImage: `url('${addWidthParameter(blockdata.image3?.url, 736)}')` }}
              />
              <div
                className="page-block-3columnimagetext__description"
                dangerouslySetInnerHTML={{ __html: blockdata.text3 }}
              />
            </div>
          </div>
        </div>
      </section>
    )
  );
};
