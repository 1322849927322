import React from "react";
import { addWidthParameter } from "../../utils/addWidthParameter";

export default ({ data, buttonLink, type }) => {
  if (!data.content.general) {
    return null;
  }
  const className = "page-block-header__title page-block-header__title--" + type;

  // data.content should be filled in, others require null check. If validation is wrong return null (if data is empty)
  const blockdata = data.content.general; // data.content.informatie && data.content.informatie.vertrekdata;

  return (
    Boolean(blockdata) && (
      <header>
        <figure className="page-block-header">
          {buttonLink && (
            <div className="page-block-header__back">
              <i className="fas fa-arrow-circle-left"></i>
              <a href={buttonLink}>Toon alle artikels</a>
            </div>
          )}

          <img src={addWidthParameter(blockdata.image?.url, 3460)} className="page-block-header__media" alt="Header" />
          <figcaption className="page-block-header__overlay">
            <div className={className}>
              <h1>{blockdata.title}</h1>
            </div>
          </figcaption>
        </figure>
      </header>
    )
  );
};
