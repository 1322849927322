export const addWidthParameter = (url, width) => {
  // Fast escape
  if (!url) {
    return url;
  }

  const queryStringIndex = url.indexOf("?");
  const queryString = queryStringIndex > -1 ? url.slice(queryStringIndex) : undefined;
  const params = new URLSearchParams(queryString);

  params.set("width", width);

  return `${url.slice(0, queryStringIndex > -1 ? queryStringIndex : undefined)}?${params.toString()}`;
};
