import { Link } from "gatsby";
import React from "react";
import { environment } from "../../environments/environment";

const PageBlockParagraphWithSidebar = ({ data, showProductButton, shippingCompanyId }) => {
  // data.content should be filled in, others require null check. If validation is wrong return null (if data is empty)
  const paragraph = data.content.paragraph;
  const sidebar = data.content.sidebar;
  const layout = data.content.layout;

  let className = "page-block-paragraphwithsidebar";

  if (!(paragraph && sidebar && layout)) {
    return null;
  }

  if (Boolean(layout.paragraphPositionLeft)) {
    className += " page-block-paragraphwithsidebar--left";
  }

  return (
    <section className={className}>
      <div className="page-block-paragraphwithsidebar__container">
        <div className="page-block-paragraphwithsidebar__body">
          <div className="page-block-paragraphwithsidebar__sidebar">
            <div dangerouslySetInnerHTML={{ __html: sidebar.text }} />
          </div>
          <div
            className="page-block-paragraphwithsidebar__paragraph"
            dangerouslySetInnerHTML={{ __html: paragraph.text }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default PageBlockParagraphWithSidebar;
