import { find } from "lodash";
import React, { useEffect, useState } from "react";
import { apiKey, catalogueId, host } from "../../../settings";

import { addYears } from "date-fns";
import { Link } from "gatsby";
import { environment } from "../../../environments/environment";
import { addWidthParameter } from "../../../utils/addWidthParameter";
import { format, getDuration } from "../../../utils/dateUtils";
import LoadingSpinner from "../../loadingSpinner";

const PageBlockDynamic = ({ theme, data, cmsProducts }) => {
  const general = data.content.general;
  const [filteredCMSProducts, setFilteredCMSProducts] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    (async function () {
      setIsloading(true);
      let products = cmsProducts;

      // Tags
      if (Boolean(general.isTags) && products) {
        products = filterCMSProductsByTag(products, general.tag);
      }

      // Cruise types
      if (Boolean(general.isCruiseTypes) && products) {
        products = filterCMSProductsByCruiseType(products, general.cruiseType);
      }

      // Shipping companies
      if (Boolean(general.isShippingCompanies) && products) {
        products = filterCMSProductsByShippingCompany(products, general.shippingCompany);
      }

      // Sailing areas
      if (Boolean(general.isSailingAreas) && products) {
        products = filterCMSProductsBySailingArea(products, general.sailingArea);
      }

      const productWithPrices = await getPrice(products);
      
      setFilteredCMSProducts(productWithPrices);
      setIsloading(false);
    })();
  }, [
    cmsProducts,
    general.isTags,
    general.tag,
    general.cruiseType,
    general.isCruiseTypes,
    general.isSailingAreas,
    general.isShippingCompanies,
    general.sailingArea,
    general.shippingCompany,
  ]);

  // Fast escape
  if (!data || !data.content || !data.content.general || !cmsProducts) {
    return <></>;
  }

  // Lifecycle
  return filteredCMSProducts.length > 0 ? (
    <section className="page-block-dynamic">
      <div className="page-block-dynamic__title">
        <h2>{data.content.general.title}</h2>
      </div>
      <div className="page-block-dynamic__products">
        {!isLoading ? (
          <>
            {filteredCMSProducts.slice(0, Math.round(data.content.general.max)).map((cmsProduct, index) => (
              <div className="page-block-dynamic__item" key={index}>
                <div className="product-card">
                  <div className="product-card__header">
                    <Link to={getPath(cmsProduct)}>
                      {Boolean(cmsProduct.content.images && cmsProduct.content.images.image1?.url) && (
                        <img
                          src={`${addWidthParameter(cmsProduct.content.images.image1.url, 1068)}`}
                          alt={getTitle(cmsProduct)}
                          className="product-card__header-media"
                        />
                      )}
                    </Link>
                  </div>
                  <h3 className="product-card__title">
                    <Link className="link link--plain" to={getPath(cmsProduct)}>
                      {getTitle(cmsProduct)}
                    </Link>
                  </h3>
                  <div className="product-card__tags">
                    <div className="product-card__tag">
                      {cmsProduct.cheapestOption ? format(new Date(cmsProduct.cheapestOption.from), "d MMMM yyy") : ""}
                    </div>
                    <div className="product-card__tag">
                      {cmsProduct.cheapestOption ? cmsProduct.cheapestOption.duration + " nachten" : ""}
                    </div>
                  </div>

                  <div className="product-card__ship">{getShipTitle(cmsProduct)}</div>
                  <div className="product-card__usps">{getThemes(cmsProduct, theme)}</div>
                  <div className="product-card__footer">
                    <div className="product-card__price">
                      <div className="product-card__price-from">Vanaf</div>
                      <span className="product-card__price-amount">
                        € {cmsProduct.cheapestOption ? cmsProduct.cheapestOption.averagePricePerPerson : "n.b."}
                      </span>
                      <span className="product-card__price-pp"> p.p.</span>
                    </div>
                    <div className="product-card__cta">
                      <Link to={getPath(cmsProduct)}>Meer info</Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="page-block-dynamic__spinner">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </section>
  ) : (
    <></>
  );
};

// Utils

const filterCMSProductsByCruiseType = (cmsProducts, cruiseType) => {
  return cmsProducts.filter(cmsProduct => {
    if (!cmsProduct || !cmsProduct.content || !cmsProduct.content.general || !cmsProduct.content.general.cruiseType) {
      return false;
    }

    return cmsProduct.content.general.cruiseType.itemId === cruiseType.itemId;
  });
};

const filterCMSProductsByShippingCompany = (cmsProducts, shippingCompany) => {
  return cmsProducts.filter(cmsProduct => {
    if (
      !cmsProduct ||
      !cmsProduct.content ||
      !cmsProduct.content.general ||
      !cmsProduct.content.general.shippingCompany
    ) {
      return false;
    }

    return cmsProduct.content.general.shippingCompany.itemId === shippingCompany.itemId;
  });
};

const filterCMSProductsBySailingArea = (cmsProducts, sailingArea) => {
  return cmsProducts.filter(cmsProduct => {
    if (
      !cmsProduct ||
      !cmsProduct.content ||
      !cmsProduct.content.general ||
      !cmsProduct.content.general.sailingAreas ||
      !cmsProduct.content.general.sailingAreas.length
    ) {
      return false;
    }

    return Boolean(
      find(cmsProduct.content.general.sailingAreas, {
        itemId: sailingArea.itemId,
      })
    );
  });
};

const filterCMSProductsByTag = (cmsProducts, tag) => {
  return cmsProducts.filter(cmsProduct => {
    if (
      !cmsProduct ||
      !cmsProduct.content ||
      !cmsProduct.content.general ||
      !cmsProduct.content.general.tags ||
      !cmsProduct.content.general.tags.length
    ) {
      return false;
    }

    return Boolean(find(cmsProduct.content.general.tags, { itemId: tag.itemId }));
  });
};

const getTitle = cmsProduct => {
  if (!cmsProduct || !cmsProduct.content || !cmsProduct.content.general || !cmsProduct.content.general.title) {
    return "";
  }

  return cmsProduct.content.general.title;
};

const getShipTitle = cmsProduct => {
  if (!cmsProduct || !cmsProduct.content || !cmsProduct.content.general || !cmsProduct.content.general.ship) {
    return "";
  }

  return `Met de ${cmsProduct.content.general.ship.name}`;
};

const getThemes = (cmsProduct, siteTheme) => {
  if (!cmsProduct || !cmsProduct.content || !cmsProduct.content.themes) {
    return "";
  }

  const themes = [cmsProduct.content.themes.theme1, cmsProduct.content.themes.theme2, cmsProduct.content.themes.theme3];
  const layoutThemes = [];

  themes.forEach((theme, index) => {
    if (theme) {
      layoutThemes.push(
        <div key={index} className="product-card__usp">
          <img src={`/${siteTheme}/svg/check.svg`} alt="check" className="product-card__usp-icon" />
          {theme.name}
        </div>
      );
    }
  });

  return layoutThemes;
};

const dateFormat = "yyyy/MM/dd";
const getPrice = async cmsProducts => {
  const products = [];

  const productIds = cmsProducts
    .map(x => x.content.general.product ? x.content.general.product.key : x.itemId);
  const from = new Date();
  const to = addYears(new Date(), 2);

  const searchRequest = {
    officeId: environment.builds[0].officeId,
    payload: {
      catalogueIds: [catalogueId],
      rooms: [
        {
          index: 0,
          pax: [
            {
              age: 25
            },
            {
              age: 25
            }
          ]
        }
      ],
      serviceType: 11,
      searchType: 1,
      fromDate: format(from, dateFormat),
      toDate: format(to, dateFormat),
      productIds: productIds
    }
  };

  const url = `${host}/api/web/booking/v2/search`;
  const result = await fetch(url, {
    method: "POST",
    headers: {
      "Api-Key": apiKey,
      "Content-Type": "application/json",
      "Language": "nl-BE"
    },
    body: JSON.stringify(searchRequest),
  });

  if (result.ok) {
    const data = await result.json();

    cmsProducts.forEach(cmsProduct => {
      const items = data.filter(x => x.name === cmsProduct.content.general.product.value);

      if (items.length) {
        const cheapest = items.sort((a, b) => a.price - b.price)[0];

        cmsProduct.cheapestOption = {
          from: cheapest.fromDate,
          duration: getDuration(cheapest.fromDate, cheapest.toDate),
          averagePricePerPerson: cheapest.averagePricePerPerson
        };

        products.push(cmsProduct);
      }
    })
  }

  return products;
};

const getPath = cmsProduct => {
  if (!cmsProduct || !cmsProduct.content || !cmsProduct.content.general) {
    return "/";
  }

  return `/products/${cmsProduct.content.general.path}`;
};

export default PageBlockDynamic;
