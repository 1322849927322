import React from "react";
import { addWidthParameter } from "../../utils/addWidthParameter";

export default ({ data }) => {
  // data.content should be filled in, others require null check. If validation is wrong return null (if data is empty)
  const blockdata = data.content.general; // data.content.informatie && data.content.informatie.vertrekdata;
  const gallery = data.content.gallery;

  let className = "page-block-textgallery";

  if (!(blockdata && gallery)) {
    return null;
  }

  return (
    <section className={className}>
      <div className="page-block-textgallery__container">
        <div className="page-block-textgallery__title">
          <h2>{blockdata.title}</h2>
        </div>
        <div className="page-block-textgallery__body">
          <div className="page-block-textgallery__image">
            <img src={addWidthParameter(gallery.image1?.url, 1200)} alt={gallery.image1?.altText} />
          </div>
          <div className="page-block-textgallery__description" dangerouslySetInnerHTML={{ __html: blockdata.text }} />
        </div>
      </div>
    </section>
  );
};
