import React from "react";
import { Helmet } from "react-helmet";

export default ({ seo, pageTitle, pagePath, siteName, canonical, pathName }) => (
  <Helmet>
    {Boolean(seo && seo.description) && <meta name="description" content={seo.description} />}
    {Boolean(seo && seo.keywords) && <meta name="keywords" content={seo.keywords} />}

    {Boolean(pageTitle) && <meta property="og:title" content={pageTitle} />}
    {Boolean(seo && seo.description) && <meta property="og:description" content={seo.description} />}
    {Boolean(pagePath) && <meta property="og:url" content={pagePath} />}
    {Boolean(seo && seo.image?.url) && <meta property="og:image" content={seo.image.url} />}
    {Boolean(seo && seo.image?.url) && <meta property="og:image:secure_url" content={seo.image.url} />}
    {Boolean(siteName) && <meta property="og:site_name" content={siteName} />}

    {Boolean(pageTitle) && <meta name="twitter:title" content={pageTitle} />}
    {Boolean(seo && seo.description) && <meta name="twitter:description" content={seo.description} />}
    {Boolean(seo && seo.image?.url) && <meta name="twitter:image" content={seo.image.url} />}

    {Boolean(canonical) && <link rel="canonical" href={`http://www.thalassacruises.be${pathName}`} />}
  </Helmet>
);
