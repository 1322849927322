import { Helmet } from "react-helmet";
import Layout from "../layout";
import PageBlock3columnimagetext from "../../components/page-blocks/page-block-3columnimagetext";
import PageBlockDynamic from "../../components/page-blocks/page-block-dynamic/pageBlockDynamic";
import PageBlockHeader from "../../components/page-blocks/page-block-header";
import PageBlockImagesidetext from "../../components/page-blocks/page-block-imagesidetext";
import PageBlockParagraphwithsidebar from "../../components/page-blocks/page-block-paragraphwithsidebar";
import React, { useContext } from "react";
import SeoHeaders from "../../components/seo/seo-headers";
import { websiteName } from "../../settings";
import GlobalContext from "../../contexts/global-provider";

const CruiseTypeWildcard = ({
  pageContext: { page, cruiseType, cmsProducts, navigation, cruiseTypes, privacy },
}) => {
  
  const {setNavigation, setPrivacy, theme} = useContext(GlobalContext);
  setNavigation(navigation);
  setPrivacy(privacy);

  const data = {
    content: {
      general: {
        title: `Aanbiedingen ${cruiseType.content.general.title}`,
        isCruiseTypes: true,
        max: 3,
        cruiseType: cruiseType,
      },
      textGallery: {
        content: {
          general: {
            title: cruiseType?.content?.textGallery?.title,
            text: cruiseType?.content?.textGallery?.text,
          },
          layout: {
            textPositionLeft: cruiseType?.content?.textGallery?.textPositionLeft,
          },
          gallery: {
            imageList: cruiseType?.content?.textGallery?.imageList,
            image1: cruiseType?.content?.textGallery?.image1,
          },
        },
      },
      paragraph: {
        content: {
          paragraph: {
            text: cruiseType?.content?.paragraph?.text,
          },
          sidebar: {
            text: cruiseType?.content?.paragraph?.sidebarText,
          },
          layout: {
            paragraphPositionLeft: cruiseType?.content?.paragraph?.paragraphPositionLeft,
          },
        },
      },
      threeColumn: {
        content: {
          general: {
            text1: cruiseType?.content?.threeColumn?.text1,
            image1: cruiseType?.content?.threeColumn?.image1,
            text2: cruiseType?.content?.threeColumn?.text2,
            image2: cruiseType?.content?.threeColumn?.image2,
            text3: cruiseType?.content?.threeColumn?.text3,
            image3: cruiseType?.content?.threeColumn?.image3,
          },
        },
      },
    },
  };

  if (!cruiseType) {
    return <></>;
  }

  // Output

  return (
    <Layout
      isTiny={true}
      hasHeader={true}
      showQsm={Boolean(page.content.layout.qsm)}
      isTinted={Boolean(page.content.layout.backgroundTinted)}
      cruiseTypes={cruiseTypes}
    >
      <SeoHeaders
        seo={cruiseType.content.seo}
        pageTitle={
          cruiseType.content.seo && cruiseType.content.seo.title
            ? cruiseType.content.seo.title
            : page.content.general.title
        }
        pagePath={page.content.general.path}
        siteName={websiteName[theme]}
      />
      <Helmet>
        <title>
          {cruiseType.content.seo && cruiseType.content.seo.title
            ? cruiseType.content.seo.title
            : page.content.general.title}
        </title>
      </Helmet>
      <PageBlockHeader data={cruiseType} />
      <PageBlockImagesidetext data={data.content.textGallery} />
      <PageBlockDynamic theme={theme} data={data} cmsProducts={cmsProducts} />
      <PageBlockParagraphwithsidebar data={data.content.paragraph} />
      <PageBlock3columnimagetext data={data.content.threeColumn} />
    </Layout>
  );
};

export default CruiseTypeWildcard;
